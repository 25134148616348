@import "~bootstrap/dist/css/bootstrap.css";
@import '~ngx-toastr/toastr';
body {
  margin: 0px !important;
  background-color:white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
body .ui-tabview .ui-tabview-panels {
  background-color: white;
}
::-webkit-scrollbar {
  width: 10px;
  height:10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-corner{
    border-radius:10px;
}

::-webkit-scrollbar-thumb {
  background-color: #0096d6;
  border-radius:10px;
}
.img-logo {
    width: 153px !important;
}
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

body input, select {
  height: 33px !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover {
  background-color: #d6f3ff !important;
  color: #0096d6  !important;
}
body .ui-fieldset .ui-fieldset-legend a {
  background-color: #0096d6  !important;
  color: white !important;
}
.ui-fieldset-toggleable .ui-fieldset-legend a {
  padding: 0.2em 1em !important;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-legend-text {
  font-size: 0.8em !important;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler {
  font-size: 0.8em !important;
  color: white !important;
}
/* body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler:hover {
  color: #0096d6  !important;
} */
body .ui-overlaypanel .ui-overlaypanel-close .ui-overlaypanel-close-icon {
  font-size: 0.9em !important;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #0096d6 !important;
  border: 1px solid #0096d6 !important;
  color: #ffffff;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color:#d6f3ff !important ;
}
body .ui-dialog-resizable .ui-dialog-content {
  overflow: hidden !important;
  overflow-y: auto !important;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #000;
  background-color: #fff; 
}
@media (min-width: 768px){
  .navbar-expand-md {
    flex-flow: wrap !important;
  }
}
@media (min-width: 1000px){
  .container {
      min-width: 920px;
  }
}
@media (min-width: 1100px){
  .container {
    min-width: 1020px;
  }
}
@media (min-width: 1200px){
  .container {
    min-width:  1120px;
  }
}
@media (min-width: 1300px){
  .container {
    min-width:  1220px;
  }
}
@media (min-width: 1400px){
  .container {
    min-width:  1320px;
  }
}
@media (min-width: 1500px){
  .container {
      width: 1420px;
  }
}
@media (min-width: 1600px){
  .container {
    min-width:  1520px;
  }
}
@media (min-width: 1700px){
  .container {
    min-width:  1620px;
  }
}

@media (min-width: 1800px){
  .container {
    min-width:  1720px;
  }
}
@media (min-width: 1900px){
      .container {
        min-width:  1820px;
      }
}
